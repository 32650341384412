import { sentTransactions, receivedTransactions, WebRTCpeer } from './peer.js';
import { setProfileColor, profile } from './receiveAsUI.js';
import { createCard, cardStorage, declineCard } from './cardUI.js';
import { showNewRoommateInUsersNearby, removeRoommateInUsersNearby, removeAllUsersNearby } from './usersnearby.js';

let webSocket;
let isRegistered = 0;

async function createSocket(){
    webSocket = new WebSocket('wss://to7i6cwqt0.execute-api.ap-south-1.amazonaws.com/development');
    await new Promise(resolve => {
        webSocket.onopen = async () => {
            requestGetColor();
            resolve();
        }
    })
    webSocket.onmessage = (event) => {
        handleOnmessageEvent(event)
    }
    webSocket.onclose = () => {
        console.log('closed');  
        webSocket = null;
        isRegistered = 0;
        removeAllUsersNearby();
    }
}

function requestGetColor() {
    console.log('Websocket connected');
    webSocket.send(JSON.stringify({ action: "message", request: "getColor" }));
}

async function handleOnmessageEvent(event){// console.log(event);
    const message = JSON.parse(event.data);
    // console.log(message);

    if (message['yourColorIs'] && message['yourHexIs']) {
        setProfileColor(message['yourColorIs'], message['yourHexIs']);
        isRegistered = 1;

        if(message['yourRoommates'] && message['yourRoommates'].length > 0) {
            for(let roommate of message['yourRoommates']){
                showNewRoommateInUsersNearby(roommate);
            }
        }

    } else if(message['newRoommate'] && message['hex']) { // { newRoommate: "crimson", hex: "DC143C" }
        showNewRoommateInUsersNearby(message);

    } else if(message['roommateLeft']) { // { roommateLeft: "earth" }
        removeRoommateInUsersNearby(message);
        // make removeReceiveAsId function

    } else if(message['_signal'] && message['_signal']['type'] && message['_signal']['type'] == "offer") {
        loadNewSignalOffer(message);

    } else if(message['_signal'] && message['_signal']['type'] && message['_signal']['type'] == "answer") {
        acceptSignalAnswer(message);
    
    } else if(message['_signal'] && message['_signal']['cancel'] && message['_signal']['cancel'] === 1) {
        loadCancellation(message);

    }

}

async function sendSignal(_signal){
    if(!webSocket) return "socket closed";
    webSocket.send( JSON.stringify( {action: "message",_signal} ) );
}

async function cancelSignal({_id,_to,wasSending,wasReceiving}){
    if(webSocket) webSocket.send(JSON.stringify({
        action: "message",
        _signal: {
            cancel: 1,
            _id,
            _from: profile.color,
            _to: _to,
            wasReceiving,
            wasSending
        }
    }));
    console.log("cancellation sent")
}

function loadNewSignalOffer(message) {
    let filesMap = new Map(message['_signal']['receivedMetadata']['files']);
    let receivedMetadata = message['_signal']['receivedMetadata'];
    let giver = { color: message['_signal']['_from'], hex: "#" + message['_signal']['_fromHex'] };
    receivedMetadata['files'] = filesMap;
    createCard({ giver, receivedMetadata, timestamp: message['_signal']['_id'], offer: message['_signal'] });
}

function acceptSignalAnswer(message) {
    const timestamp = message['_signal']['_id'];
    const cardID = sentTransactions.get(timestamp);
    const card = cardStorage.cards.get(cardID);
    card.webRTCpeer.acceptAnswerSignal(message['_signal']);
}

function loadCancellation(message) {
    let {_id, wasReceiving, wasSending} = {...message['_signal']}
    
    if(message)
    declineCard({timestamp: _id, wasReceiving, wasSending})
}

export { createSocket, webSocket, sendSignal, cancelSignal };