const clientLoadingTextTag = document.querySelector("clientloadingtext");
const receiveAsTextTag = document.querySelector("receiveastext");
const receiveAsTextColorNameTextTag = document.querySelector("receiveastext roommateid.id p");

let profile;
 
profile = {color: "crimson", hex: "#DC143C"}

function setProfileColor(color, hex) {
    console.log(color, hex)
    profile = {color, hex};
    receiveAsTextColorNameTextTag.textContent = color[0].toUpperCase() + color.slice(1);
    receiveAsTextTag.style.setProperty('--peerColor',"#"+hex);
    addClassToElements({tag: clientLoadingTextTag, className: "clientColorLoaded"});
    addClassToElements({tag: receiveAsTextTag, className: "clientColorLoaded"});
};

function addClassToElements ({tag, className}) {
    tag.classList.add(className);
}
// setProfileColor("turqouise","29CDC9");
export { setProfileColor, profile };
