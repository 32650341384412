
import { prepareToSend } from "./cardUI.js";
import { parentSearch } from "./sendofferUI.js";

const usersnearbyTag = document.querySelector("usersnearby");
const roommatesTag = document.querySelector("usersnearby roommates");
const sendArrowTag = document.querySelector("usersnearby div.sendArrow");

let roommates = {};
let recipientSelectorIdsSelected = 0;
let recipientsSelected = new Map();
let eventListener_Added_OnRoommatesTag_And_OnSendArrowTag = false;

// showNewRoommateInUsersNearby({ newRoommate: "white", hex: "FFFFFF" });

function showNewRoommateInUsersNearby({newRoommate, hex}){

    // new function to check if no roommates exist and show something else like "No People Nearby, invite someone to alpha.theviolet.app"
    
    if(roommates[newRoommate]) return;
    const tag = document.createElement("roommateid");
    roommates[newRoommate] = {hex: "#" + hex, tag};

    tag.classList.add("id",`NAME${newRoommate}`);
    tag.style.setProperty("--peerColor", "#" + hex);
    tag.insertAdjacentHTML("afterbegin", `  
        <div class="blanksquare">
            <div class="selectedsquare"></div>
        </div>
        <p>${newRoommate[0].toUpperCase() + newRoommate.slice(1)}</p>
    `);
    roommatesTag.appendChild(tag);
};

function removeRoommateInUsersNearby({roommateLeft}){
    if(roommates[roommateLeft]) {
        roommates[roommateLeft]["tag"].remove();
        delete roommates[roommateLeft];
    }
}

function removeAllUsersNearby(){
    for(let roommate of Object.keys(roommates)){
        removeRoommateInUsersNearby({roommateLeft: roommate});
    }
}

function toggleRecipientSelector(){
    usersnearbyTag.classList.toggle("recipientSelectorToggled");
    toggle_EventListener_OnRoommatesTag_And_OnSendArrowTag()
}

function toggle_EventListener_OnRoommatesTag_And_OnSendArrowTag(){
    if(eventListener_Added_OnRoommatesTag_And_OnSendArrowTag){
        roommatesTag.removeEventListener('click', e => { handleRoommateIdClickEvent(e) });
        sendArrowTag.removeEventListener('click', e => { handleSendArrowClickEvent(e) });
        
    } else if(!eventListener_Added_OnRoommatesTag_And_OnSendArrowTag){
        roommatesTag.addEventListener('click', e => { handleRoommateIdClickEvent(e) });
        sendArrowTag.addEventListener('click', e => { handleSendArrowClickEvent(e) });
    }
}

function handleSendArrowClickEvent(e){
    if(recipientsSelected.size === 0) return;
    prepareToSend(recipientsSelected);
}

function handleRoommateIdClickEvent(e){
    const roommateidTag = parentSearch(e.target, "ROOMMATEID", "ROOMMATES");
    if(roommateidTag){
        const roommateidName = findRoommateIdName(roommateidTag);
        addOrRemoveRecipientSelection(roommateidName);
        toggle_IdSelectedState_In_RecipientSelector(roommateidName);
    } else if(!roommateidTag) return;
}

function addOrRemoveRecipientSelection(roommateidName){

    if (recipientsSelected.has(roommateidName)){
        recipientsSelected.delete(roommateidName)
    } else if (!recipientsSelected.has(roommateidName)){
        recipientsSelected.set(roommateidName, {color: roommateidName, hex: roommates[roommateidName]["hex"]})
    }
}

function findRoommateIdName(roommateid){
    const roommateidClassList = Array.from(roommateid.classList);
    for(let aClass of roommateidClassList){
        if (aClass.slice(0,4) === "NAME") return aClass.slice(4);
    }
}

function toggle_IdSelectedState_In_RecipientSelector(roommateidName){
    roommates[roommateidName]['tag'].classList.toggle("idSelected");
    if(recipientsSelected.size === 0){
        roommatesTag.parentNode.classList.remove("idsSelected");
    } else if(recipientsSelected.size >= 0){
        roommatesTag.parentNode.classList.add("idsSelected");
    }
}

function clearRecipientSelection(){
    for(let recipientName of recipientsSelected.keys()){
        addOrRemoveRecipientSelection(recipientName)
        toggle_IdSelectedState_In_RecipientSelector(recipientName)
    }
    toggleRecipientSelector();
}

export { showNewRoommateInUsersNearby, removeRoommateInUsersNearby, removeAllUsersNearby, toggleRecipientSelector, clearRecipientSelection };