import { totalFilesForChose, dismissChoseFiles, showSize } from "./sendofferUI.js";
import { createTransaction, acceptTransaction, sentTransactions, receivedTransactions } from "./peer.js";
import { cancelSignal } from "./register.js";

const sentRequestsTag = document.querySelector("home sentrequests");
const receivedRequestsTag = document.querySelector("home receivedrequests");

let cardStorage = {count: 0, cards: new Map()}

function prepareToSend(recipients){
    const totalFilesChose = new Object(totalFilesForChose);
    for(let recipient of recipients.values()){
        const timestamp = new Date().getTime()
        const webRTCpeer = createTransaction({totalFilesChose,timestamp,recipient});
        createCard({recipient, totalFilesChose, timestamp, webRTCpeer});
        setTimeout(() => {}, 2); // to avoid getting the same timestamp for every recipient
        // const sendCard = new TransactionCard({recipient});
    }
    for (let file of totalFilesForChose.files.entries()) {
        // console.log(file[1])
    }
    dismissChoseFiles();
    // console.log(totalFilesChose)
}

function createCard({recipient, giver, totalFilesChose, receivedMetadata, timestamp, webRTCpeer, offer}) {
    let cardType;
    if(recipient && !giver){ cardType = "send"; } // if sending (recipient exists)
    else if(!recipient && giver){ cardType = "receive"; } // if receiving (giver exists)
    else return;

    const cardTag = document.createElement(cardType==="send"? "sendcard":"receivecard");
    cardTag.classList.add("waiting");
    cardTag.setAttribute("style",`--peerColor: ${cardType==="send"? recipient["hex"]:giver["hex"]};`);
    cardType==="send"?
        sentRequestsTag.appendChild(cardTag) :
        receivedRequestsTag.appendChild(cardTag);

    const cardID = cardType.charAt(0) + cardStorage["count"];
    cardStorage["cards"].set(cardID,
        {
            cardTag, cardType, recipient, giver, totalFilesChose, receivedMetadata, webRTCpeer, offer, timestamp,
            howMuchDoneInSize: 0,
            howManyFilesDone: 0
        }
    );
    cardStorage["count"]++;
    const card = cardStorage["cards"].get(cardID);

    if(cardType == "send")
        webRTCpeer.card = card;

    // if(transactions.has(timestamp)) console.log("booomerang");
    cardID.charAt(0) == "s"?
        sentTransactions.set(timestamp, cardID) :
        receivedTransactions.set(timestamp, cardID);

    cardType==="send"? 
        fillSendCard_With_WaitingStage({cardTag, cardID, recipient, timestamp, totalFilesChose, card}) :
        fillReceiveCard_With_WaitingStage({cardTag, cardID, giver, receivedMetadata, timestamp, card});
}

function fillSendCard_With_WaitingStage({cardTag, cardID, recipient, timestamp, totalFilesChose, card}) {

    createSendCardTitleTag({cardTag,cardID,recipient});
    
    const cancelButtonTag = document.createElement("button");
    cancelButtonTag.classList.add("cancelButton");
    cancelButtonTag.insertAdjacentHTML("beforeend",`
        <p class="cancelbuttontext">Cancel</p><p class="dismissbuttontext">Dismiss</p><svg class="crossIcon" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.5 0L7.5 6L13.5 0L15 1.5L9 7.5L15 13.5L13.5 15L7.5 9L1.5 15L0 13.5L6 7.5L0 1.5L1.5 0Z" fill="black"/>
        </svg>
    `);
    cancelButtonTag.addEventListener("click", function (e) {
        if(cardTag.classList.contains("declined") || cardTag.classList.contains("done")) dismissCard({card, cardID, timestamp});
        else dismissCard({card, cardID, isCancelling:true, timestamp})
    })
    cardTag.appendChild(cancelButtonTag);

    cardStorage.cards.get(cardID)["cancelButtonTag"] = cancelButtonTag;

    createSendCardFooterTag({cardTag, totalFilesChose, cardID});
    
}

function createSendCardTitleTag({cardTag,cardID,recipient}) {
    const titleTag = document.createElement("div");
    const titleBoldTag = document.createElement("b");
    const titleSpanTag = document.createElement("span");
    const titlePTag = document.createElement("p");

    titlePTag.classList.add("declined")

    // titleTag.insertAdjacentHTML("beforeend", recipient);
    titleBoldTag.textContent = "Waiting for";
    titleSpanTag.textContent = recipient.color.charAt(0).toUpperCase() + recipient.color.slice(1);
    titlePTag.textContent = "declined";


    titleTag.classList.add("sendcardtitle");
    titleTag.appendChild(titleBoldTag);
    titleTag.appendChild(titleSpanTag);
    titleTag.appendChild(titlePTag);
    cardTag.appendChild(titleTag);
    cardStorage.cards.get(cardID).titleBoldTag = titleBoldTag;
}

function createSendCardFooterTag({cardTag, totalFilesChose, cardID}) {
    const footerTag = document.createElement("sendcardfooter");

    cardStorage.cards.get(cardID).footer = {footerTag};
    const footerStorage = cardStorage.cards.get(cardID).footer;

    createCardTotalFileSize({footerTag, footerStorage, totalFilesChose});
    createCardTotalFileCount({footerTag, footerStorage, totalFilesChose});

    footerTag.insertAdjacentHTML("afterbegin",`
        <svg class="sendArrow" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M21 10.5L0 0L3.15 9L21 10.5ZM21 10.5L3.15 12L0 21L21 10.5Z" fill="#EEEEEE"/>
        </svg>
    `);
    cardTag.appendChild(footerTag);
}

function createCardTotalFileSize({footerTag, footerStorage, totalFilesChose}) {
    const footerFileSizeTag = document.createElement("div");
    footerFileSizeTag.classList.add("fileSize");
    footerTag.appendChild(footerFileSizeTag);

    const footerFileSizeWhiteTag = document.createElement("p");
    footerFileSizeWhiteTag.classList.add("white");
    footerFileSizeWhiteTag.textContent = totalFilesChose.totalSizeReadable;
    footerFileSizeTag.appendChild(footerFileSizeWhiteTag);
    footerStorage.footerFileSizeWhiteTag = footerFileSizeWhiteTag;
    
    const footerFileSizeGreyTag = document.createElement("p");
    footerFileSizeGreyTag.classList.add("grey");
    footerFileSizeTag.appendChild(footerFileSizeGreyTag);
    footerStorage.footerFileSizeGreyTag = footerFileSizeGreyTag;
}

function createCardTotalFileCount({footerTag, footerStorage, totalFilesChose}) {
    const footerFileCountTag = document.createElement("div");
    footerFileCountTag.classList.add("fileCount");
    footerTag.appendChild(footerFileCountTag);

    const footerFileCountWhiteTag = document.createElement("p");
    footerFileCountWhiteTag.classList.add("white");
    footerFileCountWhiteTag.textContent = totalFilesChose.fileCount + `${totalFilesChose.fileCount > 1 ? " files" : " file"}`;
    footerFileCountTag.appendChild(footerFileCountWhiteTag);
    footerStorage.footerFileCountWhiteTag = footerFileCountWhiteTag;
    
    const footerFileCountGreyTag = document.createElement("p");
    footerFileCountGreyTag.classList.add("grey");
    footerFileCountTag.appendChild(footerFileCountGreyTag);
    footerStorage.footerFileCountGreyTag = footerFileCountGreyTag;
}

function fill_Card_With_DeclineStage({cardID}) {
    // console.log(cardStorage.cards.get(cardID))
    cardStorage.cards.get(cardID)["cancelButtonTag"].classList.toggle("dismissButton", "clear");

    cardStorage.cards.get(cardID)["cardTag"].setAttribute("class","declined");
}

function fillReceiveCard_With_WaitingStage({cardTag, cardID, giver, receivedMetadata, timestamp, card}) {
    
    createReceiveCardTitleTag({cardTag, giver, receivedMetadata, cardID});
    
    create_ReceiveCard_RequestChoice({cardTag, cardID, timestamp, card});
}

function createReceiveCardTitleTag({cardTag, giver, receivedMetadata, cardID}) {
    const titleTag = document.createElement("div");
    const titlePTag = document.createElement("p");
    
    // sendCardTitleTag.insertAdjacentHTML("beforeend", recipient);
    titlePTag.textContent = `${receivedMetadata.fileCount} ${receivedMetadata.fileCount===1? "file":"files"}, ${receivedMetadata.totalSizeReadable} incoming from`;
    
    titleTag.classList.add("receivecardtitle");
    titleTag.appendChild(titlePTag);
    cardTag.appendChild(titleTag);
    
    cardTag.insertAdjacentHTML("beforeend",`
        <roommateid class="id">
            <div class="blanksquare"></div>
            <p>${giver["color"].charAt(0).toUpperCase() + giver["color"].slice(1)}</p>
        </roommateid>
    `)

    cardStorage.cards.get(cardID).titleTag = titleTag;
}

function create_ReceiveCard_RequestChoice({cardTag, cardID, timestamp, card}) {

    // const receiveCardRequestChoiceTag = document.createElement("requestchoice");

    const choiceButtonsTag = document.createElement("div");
    choiceButtonsTag.classList.add("choiceButtons");

    const downloadButtonTag = document.createElement("button");
    downloadButtonTag.classList.add("downloadButton");
    downloadButtonTag.addEventListener("click", function (e) {
        if(cardTag.classList.contains("waiting")) {
            acceptTransaction({timestamp, card});
            fill_Card_With_LoadingStage({card, cardTag, cardID, timestamp, isReceiving: true});
        }
    })

    const cancelButtonTag = document.createElement("button");
    cancelButtonTag.classList.add("cancelButton", "clear");
    cancelButtonTag.addEventListener("click", function(e) {
        if(cardTag.classList.contains("declined") || cardTag.classList.contains("done")) dismissCard({card, cardID, timestamp});
        else dismissCard({card, cardID, isCancelling:true, timestamp})
    })
    
    downloadButtonTag.insertAdjacentHTML("beforeend",`
        <p>Download</p>
        <svg class="downloadIcon" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.5 13.5H13.5V8.625L12 7.125H15V15H0V7.125H3L1.5 8.625V13.5Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 10.5L12.75 0L8.25 1.575L7.5 10.5ZM7.5 10.5L6.75 1.575L2.25 -4.58969e-07L7.5 10.5Z" fill="white"/>
        </svg>`);
    cancelButtonTag.insertAdjacentHTML("beforeend",` 
        <p class="cancelbuttontext">Cancel</p><p class="dismissbuttontext">Dismiss</p><svg class="crossIcon" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.5 0L7.5 6L13.5 0L15 1.5L9 7.5L15 13.5L13.5 15L7.5 9L1.5 15L0 13.5L6 7.5L0 1.5L1.5 0Z" fill="black"/>
        </svg>`);

    choiceButtonsTag.appendChild(downloadButtonTag);
    choiceButtonsTag.appendChild(cancelButtonTag);
    cardTag.appendChild(choiceButtonsTag);

    cardStorage.cards.get(cardID).cancelButtonTag = cancelButtonTag;

}

function fill_Card_With_LoadingStage({card, cardTag, isReceiving, isSending}) {

    let cardType;
    // if(isSending) cardType = "send";
    // else if(isReceiving) cardType = "receive";
    // else return;
    // console.log("isSending: ",isSending);
    // console.log("isReceiving: ",isReceiving);

    cardType = card.cardType;

    cardTag.setAttribute("class","loading");
    card.cancelButtonTag.classList.remove("clear");

    if(cardType == "receive"){
        fill_ReceiveCard_TitleTag_With_ProgressAndGiverName({card});
        fill_ReceiveCard_With_FooterTag({card, cardTag});
    }
    card.titleBoldTag.textContent = "0%";
    
    update_Card_FooterFileInfo({card, cardType});

    fill_Card_With_Files({card, cardTag, cardType});

}

function fill_ReceiveCard_TitleTag_With_ProgressAndGiverName({card}) {
    const titleBoldTag = document.createElement("b");
    const titleSpanTag = document.createElement("span");
    const giver = card.giver;
    
    titleSpanTag.textContent = giver.color.charAt(0).toUpperCase() + giver.color.slice(1);
    
    card.titleTag.appendChild(titleBoldTag);
    card.titleTag.appendChild(titleSpanTag);
    card.titleBoldTag = titleBoldTag;
}

function fill_ReceiveCard_With_FooterTag({card, cardTag}) {
    const footerTag = document.createElement("receivecardfooter");
    
    card.footer = {footerTag};
    const footerStorage = card.footer;
    
    createCardTotalFileCount({footerTag, footerStorage, totalFilesChose: card.receivedMetadata})
    createCardTotalFileSize({footerTag, footerStorage, totalFilesChose: card.receivedMetadata})
    
    footerTag.insertAdjacentHTML("afterbegin",`
        <svg class="downloadIcon" width="21" height="21" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.5 13.5H13.5V8.625L12 7.125H15V15H0V7.125H3L1.5 8.625V13.5Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 10.5L12.75 0L8.25 1.575L7.5 10.5ZM7.5 10.5L6.75 1.575L2.25 -4.58969e-07L7.5 10.5Z" fill="white"/>
        </svg>
    `);

    cardTag.appendChild(footerTag);
}

function update_Card_FooterFileInfo({card, cardType}) {
    const metaData = card.totalFilesChose ? card.totalFilesChose : card.receivedMetadata;

    card.footer.footerFileCountWhiteTag.textContent = `0 files ${cardType == "send"? "sent":"received"}`;
    card.footer.footerFileCountGreyTag.textContent = `out of ${metaData.fileCount} ${metaData.fileCount > 1 ? " files" : " file"}`;

    card.footer.footerFileSizeWhiteTag.textContent = `0 Bytes ${cardType == "send"? "sent":"received"}`;
    card.footer.footerFileSizeGreyTag.textContent = `out of ${metaData.totalSizeReadable}`;
}

function fill_Card_With_Files({card, cardTag, cardType}) {

    const filesTag = document.createElement("files");
    cardTag.appendChild(filesTag);

    const files = card.totalFilesChose ? card.totalFilesChose.files : card.receivedMetadata.files;
    console.log(files);
    card.afileStorage = new Map();
    for (let [filename,file] of files.entries()) {
        create_AfileTag_forCard({card, filename, file, filesTag});
    } 
    // dummyLoading({card, cardType})
}

function create_AfileTag_forCard({card, filename, file, filesTag}) {

    const afileTag = document.createElement("afile");
    afileTag.classList.add("loading");
    filesTag.appendChild(afileTag);

    afileTag.insertAdjacentHTML("beforeend",`
            <svg class="fileIcon" width="18" height="25" viewBox="0 0 18 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 7.89629V25H17.5V0H8.18348L0 7.89629Z" fill="#EEEEEE"/>
            </svg>

            <svg class="crossIcon" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.5 0L7.5 6L13.5 0L15 1.5L9 7.5L15 13.5L13.5 15L7.5 9L1.5 15L0 13.5L6 7.5L0 1.5L1.5 0Z" fill="#EEEEEE"/>
            </svg>               

            <p class="fileName">${filename}</p>
    `)

    const afileSizeTag = document.createElement("p");
    afileSizeTag.classList.add("fileSize");
    const afileSizeBoldTag = document.createElement("b");
    afileSizeBoldTag.textContent = "0 Bytes";

    afileSizeTag.appendChild(afileSizeBoldTag);
    afileSizeTag.insertAdjacentHTML("beforeend",`<span> / </span>${showSize(file.size)}`);
    afileTag.appendChild(afileSizeTag);

    const afileProgressBar = document.createElement("div");
    afileProgressBar.classList.add("progressBar");
    afileProgressBar.insertAdjacentHTML("beforeend",`
        <div class="foreground"></div>
        <div class="background"></div>
    `)
    afileTag.appendChild(afileProgressBar);

    card.afileStorage.set(filename,{afileTag, afileProgressBar, afileSizeTag, afileSizeBoldTag})
}

function declineCard({timestamp, wasSending, wasReceiving}) {
    
    const cardID = wasSending ? receivedTransactions.get(timestamp) :
    wasReceiving ? sentTransactions.get(timestamp) : 
    null;
    
    const card = cardStorage.cards.get(cardID);
    const tagClasslist = card.cardTag.classList;

    if(wasSending && tagClasslist.contains("waiting"))    // receiveCard while waiting will disappear if sender cancels
        dismissCard({card, cardID, timestamp})

    else if(tagClasslist.contains("receiving"))    // receiveCard while downloading will go to decline stage if sender cancels
        fill_Card_With_DeclineStage({cardID})

    else if(wasReceiving)    // sendCard will go to decline stage if receiver cancels
        fill_Card_With_DeclineStage({cardID})
}

function dismissCard({card, cardID, isCancelling, timestamp}) {

    card.cardTag.remove()

    let transactions, wasSending, wasReceiving;

    if(cardID.charAt(0) == "s") {
        transactions = sentTransactions;
        wasSending = true;
    } else if(cardID.charAt(0) == "r") {
        transactions = receivedTransactions;
        wasReceiving = true;
    }
    transactions.delete(timestamp);

    if(isCancelling) {
        if(card.webRTCpeer && card.webRTCpeer.connected) card.webRTCpeer.cancel_connection();
        else if(!card.webRTCpeer || !card.webRTCpeer.connected) cancelSignal({
            _id: card.timestamp,
            _to: card.recipient? card.recipient.color : card.giver.color,
            wasSending, wasReceiving
        })
        // if(cardID.charAt(0) == "s") cancelTransaction({timestamp, action:"send"});
        // else if(cardID.charAt(0) == "r") cancelTransaction({timestamp, action:"receive"});
    }
    cardStorage.cards.delete(cardID);
}

function errorCard({card}) {

}

function getProgressUpdaterForChunking({card}) {
    const afile = card.afileStorage
}

function updateAfileProgress({afile, fileDoneInSize, totalSize}) {
    const afileProgressBar = afile.afileProgressBar;
    const afileSizeBoldTag = afile.afileSizeBoldTag;

    const percent = (fileDoneInSize / totalSize) * 100;

    afileProgressBar.style.setProperty("--progressInPercent",percent+'%');
    afileSizeBoldTag.textContent = showSize(fileDoneInSize);
}

function initiate_Card_ProgressUpdaterInterval({card}) {
    const totalSize = card.totalFilesChose? card.totalFilesChose.totalSize: card.receivedMetadata.totalSize;
    const footerFileCountWhiteTag = card.footer.footerFileCountWhiteTag;
    const footerFileSizeWhiteTag = card.footer.footerFileSizeWhiteTag;
    const interval = setInterval(() => {
        footerFileSizeWhiteTag.textContent = showSize(card.howMuchDoneInSize) + " " + (card.cardType == "send"? "sent": "received");
        card.titleBoldTag.textContent = `${Math.floor((card.howMuchDoneInSize / totalSize) * 100)}%`;
        footerFileCountWhiteTag.textContent = card.howManyFilesDone + " " + (card.howManyFilesDone == 1? "file":"files") + " " + (card.cardType == "send"? "sent": "received");
        console.log("howMuchDoneInSize" + card.howMuchDoneInSize);
        console.log("totalSize" + totalSize);
        if(card.howMuchDoneInSize >= totalSize || !card.webRTCpeer.peer) {
            card_Done({card});
            clearInterval(interval);
        };
    },400)
}

function afile_Done({filename, afile, card}) {
    afile.afileTag.setAttribute("class","done");
    card.howManyFilesDone++;
}

function card_Done({card}) {
    card.cardTag.setAttribute("class","done");
    card.cancelButtonTag.classList.add("dismissButton","clear");
    
}

function dummyLoading({card, cardType}) {
    const files = cardType == "send"? card.totalFilesChose.files : card.receivedMetadata.files;
    initiate_Card_ProgressUpdaterInterval({card});

    for(let [filename, file] of files.entries()){
        let fileDoneInSize = 0;

        const doProgress = setInterval(() => {
            fileDoneInSize += file.size / 100;
            card.howMuchDoneInSize += file.size / 100;
            updateAfileProgress({afileProgressBar, fileDoneInSize, totalSize:file.size, afileSizeBoldTag});
            if(fileDoneInSize >= file.size) {
                afile_Done({filename, afile, card});
                clearInterval(doProgress);
            };
        }, 20);
    }
}

export { prepareToSend, createCard, declineCard, fill_Card_With_LoadingStage, errorCard, cardStorage, initiate_Card_ProgressUpdaterInterval, updateAfileProgress, afile_Done };
