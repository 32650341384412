

const metaparse = (b) => {
    let brem = b.slice(9);
    const metadata = {};
    switch (b.slice(4,8)) {
        case 'mssg':
            metadata.mssg = brem;
            break;
        case 'link':
            metadata.link = brem;
            break;
        case 'file':
            metadata.file = brem.slice(0,brem.search(','));
            let commaPosition = brem.search(',') + 1;
            brem = brem.slice(commaPosition);
            metadata.bytelength = brem.slice(5);
            break;
    }

    return metadata;
}

export { metaparse };