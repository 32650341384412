

// import {
//     showDirectoryPicker,
//     showOpenFilePicker,
//     showSaveFilePicker,
//     FileSystemDirectoryHandle,
//     FileSystemFileHandle,
//     FileSystemHandle,
//     FileSystemWritableFileStream,
//     getOriginPrivateDirectory
// } from 'https://cdn.jsdelivr.net/npm/native-file-system-adapter/src/es6.js'
import {
    showOpenFilePicker, showDirectoryPicker
} from 'https://cdn.jsdelivr.net/gh/jimmywarting/native-file-system-adapter/src/es6.js'
// } from 'native-file-system-adapter/src/es6.js'
// } from 'https://cdn.jsdelivr.net/gh/jimmywarting/native-file-system-adapter/src/es6.js'
// } from './src/es6.js'

import { clearRecipientSelection, toggleRecipientSelector } from './usersnearby.js';

const homeTag = document.querySelector('home');
const choosefilesbuttonTag = document.querySelector('send choosefilesbutton')
const sendTag = document.querySelector('send')
const selectedfilesTag = document.querySelector('send selectedfiles')
const sendFileCountTag = document.querySelector('send sendtitle form .numberoffiles')
const sendFormTag = document.querySelector('send sendtitle form')
const sendButtonTag = document.querySelector('send sendtitle form button')
const addMoreButtonTag = document.querySelector('send addmorebutton')
const cancelChoseFilesButtonTag = document.querySelector('send sendtitle button.cancelButton')
const dragNdropOverlay = document.querySelector('overlay.dragNdropOverlay')

const fileIcon = `
    <svg class="fileIcon" width="18" height="25" viewBox="0 0 18 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 7.89602V25H17.5V0H8.1832L0 7.89602Z" fill="#EEEEEE"/>
    </svg>
`
const fileCrossIcon = `
    <svg class="fileCrossIcon" width="15" height="15" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.35 0L4.75 3.4L8.15 0L9 0.85L5.6 4.25L9 7.65L8.15 8.5L4.75 5.1L1.35 8.5L0.5 7.65L3.9 4.25L0.5 0.85L1.35 0Z" fill="#EEEEEE"/>
    </svg>
`
const undoIcon = `
    <svg class="undoIcon" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.60476 0V1.55385L2.30357 2.74577H5.83963C6.96264 2.74577 7.83785 2.91531 8.50958 3.32552C9.19493 3.74406 9.58404 4.36084 9.84629 5.08145C10.0035 5.51339 10.0196 6.03733 9.98454 6.53019C9.94858 7.0354 9.85371 7.56683 9.74653 8.04368C9.63893 8.52241 9.51637 8.95724 9.42095 9.27188C9.37315 9.42951 9.33192 9.55777 9.30243 9.6472C9.28769 9.69193 9.27586 9.72701 9.2676 9.75124L9.26737 9.75192L9.18193 10L8.62387 9.83588L8.28568 9.73632L8.28517 9.73617L8.28437 9.73594L8.05192 9.6675L8.13995 9.41174C8.14713 9.39069 8.15782 9.35899 8.17139 9.31783C8.19855 9.23546 8.23717 9.11537 8.28221 8.96683C8.3725 8.66911 8.48763 8.26026 8.58801 7.81365C8.68882 7.36516 8.77222 6.88962 8.8031 6.45588C8.83487 6.00964 8.8064 5.66275 8.72591 5.44159C8.51279 4.85597 8.24832 4.4929 7.86401 4.25821C7.46615 4.01524 6.85582 3.8582 5.83963 3.8582H2.30357L3.60476 5.05012V6.60399L0 3.302L3.60476 0Z" fill="white"/>
    </svg>

`
let totalFilesForChose = {fileCount: 0, totalSizeReadable: "0 Bytes", totalSize: 0, files: new Map()}
let removedFiles = new Map()
let loaded = false
let state = 0;
let undoPromptTimeouts = new Map()

function addChooseFileListeners() {
    sendTag.addEventListener('click', evt => {if(!state) chooseFiles()})
    addMoreButtonTag.addEventListener('click', chooseFiles)
    cancelChoseFilesButtonTag.addEventListener('click', dismissChoseFiles)
    selectedfilesTag.addEventListener('click', handleChoseAfileClick)
    sendFormTag.addEventListener('submit', prepareToSendOld)

    addDragDropListeners()
}

function addDragDropListeners() {
    window.addEventListener('drop', handleWindowDrop)
    window.addEventListener('dragenter', evt => {evt.preventDefault()})
    window.addEventListener('dragover', evt => {
        evt.preventDefault()
        if(evt.dataTransfer.types.includes("Files","application/x-moz-file")){
            console.log("true");
            dragNdropOverlay.classList.add("active")
        }
    })
    window.addEventListener('dragleave', evt => {
        dragNdropOverlay.classList.remove("active");
    })
}

function chooseFiles() {
    showOpenFilePicker({multiple: true, _preferPolyfill: true}).then(files => {
        console.log(files);
        if(files.length){
            setSendStateTo1()
            addChoseFiles(files)
        } else if(files["name"]){
            setSendStateTo1()
            addChoseFiles([files])
        }
    })
}

function setSendStateTo1() {
    if(sendTag.classList.contains("sendState0Init") || sendTag.classList.contains("sendState0Loaded")) {
        homeTag.setAttribute('class', "filesAreLoaded")
        sendTag.setAttribute('class', "sendState1FilesSelected")
        toggleRecipientSelector()
        state = 1;
    }
    // sendButtonTag.setAttribute('class', "used")

}

function addChoseFiles(filesInput) {
    const filteredFiles = filesInput.filter(file => !totalFilesForChose["files"].has(file.name))
    if(filteredFiles.length == 0) return
    filteredFiles.forEach(file => {
        file.getFile().then(info => {
            totalFilesForChose["files"].set(file.name,{file, size: info.size})
            injectAfileForChose(info)
        })
    })
    totalFilesForChose["fileCount"] += filteredFiles.length
    updateChoseFilesInfo(filteredFiles)
}

function updateChoseFilesInfo(filteredFiles) {
    getTotalSizeOfSelectedFiles(filteredFiles).then(totalSize => {
        totalFilesForChose["totalSize"] += totalSize
        totalFilesForChose["totalSizeReadable"] = showSize( totalFilesForChose["totalSize"] )
        sendFileCountTag.textContent = `${totalFilesForChose["fileCount"]} ${totalFilesForChose["fileCount"]>1?"files":"file"} | ${showSize(totalFilesForChose["totalSize"])}`
    })
}

async function getTotalSizeOfSelectedFiles(files) {
    const filesPromiseArray = files.map(file => file.getFile())
    const filesArray = await Promise.all(filesPromiseArray)
    const sizeMap = filesArray.map(file_1 => file_1.size)
    return sizeMap.reduce((accumulator, currentValue) => accumulator + currentValue)
}

function injectAfileForChose(fileinfo) {
    const afile = document.createElement("afile")
    afile.insertAdjacentHTML("beforeend",`
        <card>
            ${fileIcon}
            <p>${fileinfo.name}<span>, ${showSize(fileinfo.size)}</span></p>
            ${fileCrossIcon}
        </card>
        <undoprompt>
            <p>Removed</p>
            <button type="button" class="point afileUndoPrompt">
                <p>undo</p>
                ${undoIcon}
            </button>
        </undoprompt>
    `)
    afile.setAttribute("_filename",fileinfo.name)
    selectedfilesTag.appendChild(afile)
}

function setSendStateTo0() {
    if(sendTag.classList.contains("sendState1FilesSelected")) {
        setTimeout(()=>{state=0}, 50)
        if(loaded){
            sendTag.setAttribute('class', "sendState0Loaded")
        } else if(!loaded){
            sendTag.setAttribute('class', "sendState0Init")
        }
        homeTag.classList.remove("filesAreLoaded")
    }
}

function dismissChoseFiles() {
    setSendStateTo0()
    clearRecipientSelection()
    clearChoseFiles()
    sendFormTag.reset()
}

function clearChoseFiles() {
    totalFilesForChose = {fileCount: 0, totalSizeReadable: "0 Bytes", totalSize: 0, files: new Map()}
    removedFiles = new Map()
    selectedfilesTag.innerHTML = ''
}

function handleChoseAfileClick(evt) {
    const tag = evt.target
    const parentAfile = parentSearch(tag, "AFILE", "SELECTEDFILES")
    if (parentAfile) {
        if (checkIfClickedOnAfileFileCrossIcon(tag)) {
            triggerAfileUndoPrompt(parentAfile)
        } else if (checkIfClickedOnAfileUndoPrompt(tag)) {
            parentAfile.classList.remove("removedAfile")
            removedFiles.delete(parentAfile.getAttribute("_filename"))
        }
    }
}

function checkIfClickedOnAfileFileCrossIcon(tag) {
    return tag.classList.contains("fileCrossIcon") || tag.parentNode.classList.contains("fileCrossIcon")
}

function triggerAfileUndoPrompt(targetAfile) {
    targetAfile.classList.add("removedAfile")
    triggerAfileRemoveTimeout(targetAfile)
}

function checkIfClickedOnAfileUndoPrompt(tag) {
    return tag.classList.contains("afileUndoPrompt") || tag.parentNode.classList.contains("afileUndoPrompt")
}

function triggerAfileRemoveTimeout(targetAfile) {
    const filename = targetAfile.getAttribute("_filename")
    removedFiles.set(filename, totalFilesForChose["files"].get(filename))
    setTimeout(() => {
        if(targetAfile.classList.contains("removedAfile") && totalFilesForChose.fileCount !== 0) {
            removeChoseAfile(targetAfile)
        } 
    },3000)
}

async function removeChoseAfile(targetAfile) {
    targetAfile.remove()
    const filename = targetAfile.getAttribute("_filename")
    totalFilesForChose.files.delete(filename)
    totalFilesForChose.fileCount = totalFilesForChose.files.size
    if(totalFilesForChose.fileCount === 0) {
        dismissChoseFiles();
        return;
    }
    const fileSize = totalFilesForChose.files.get(filename)["size"]
    totalFilesForChose["totalSize"] -= fileSize
    sendFileCountTag.textContent = `${totalFilesForChose["fileCount"]} ${totalFilesForChose["fileCount"]>1?"files":"file"} | ${showSize(totalFilesForChose["totalSize"])}`
}

function parentSearch(tag,targetParentNodeName,limitSearchToParentNodeName) {
    if(tag.nodeName === targetParentNodeName){
        return tag
    } else if(tag.nodeName === limitSearchToParentNodeName) {
        return false
    } else {
        return parentSearch(tag.parentNode,targetParentNodeName,limitSearchToParentNodeName)
    }
}

function showSize(size){
    if(size>=1024 && size<1048576){
        return (size/1024).toFixed(2) + " KB";
        
    } else if(size>=1048576 && size<1073741824){
        return (size/1048576).toFixed(2) + " MB";
        
    } else if(size>=1073741824){
        return (size/1073741824).toFixed(2) + " GB";
        
    } else if(size<1024 && size>1){
        return (size).toFixed(2) + " Bytes";
        
    } else if(size==1){
        return (size).toFixed(2) + " Byte";

    } else if(size==0){
        return (size).toFixed(2) + " Bytes";
    }
}

function prepareToSendOld(evt) {
    evt.preventDefault()
    console.log(...new FormData(evt.target))
}

async function handleWindowDrop(evt) {

    dragNdropOverlay.classList.remove("active");
    evt.preventDefault()
    const files = []
    // const directories = []
    for (let item of evt.dataTransfer.items) {
        if(item.kind == "file") {
            const handle = await item.getAsFileSystemHandle()
            if(handle.kind == "file") 
                files.push(handle)
            // if(handle.kind == "directory")
            //     directories.push(handle)
            //     console.log("directory")
        }
    }

    if(files) {
        setSendStateTo1()
        addChoseFiles(files)
    }
    // if(directories) {
    //     setSendStateTo1()
    //     console.log(directories)
    // }

}

export { addChooseFileListeners, parentSearch, totalFilesForChose, dismissChoseFiles, showSize }
