import { createCard, declineCard, fill_Card_With_LoadingStage, errorCard, initiate_Card_ProgressUpdaterInterval, updateAfileProgress, afile_Done } from './cardUI.js';
import { profile } from './receiveAsUI.js';
import { Unchunkit, Chunkit } from './chunking.js';
import { webSocket, sendSignal } from './register.js';
import { metaparse } from './metaparser.js';

let sentTransactions = new Map() // (timestamp, cardID);
let receivedTransactions = new Map() // (timestamp, cardID);

function createTransaction({totalFilesChose, timestamp, recipient}) {
    // for now, just directly call createCard for receiving
    // const giver = "crimson";
    const webRTCpeer = new WebRTCpeer({initiate: true})
    
    let filesMap = new Map();
    for(let [key,value] of totalFilesChose["files"]){
        filesMap.set(key, {size: value["size"]} );
    }
    const receivedMetadata = {
        fileCount: totalFilesChose["fileCount"],
        totalSizeReadable: totalFilesChose["totalSizeReadable"],
        totalSize: totalFilesChose["totalSize"],
        files: Array.from( filesMap.entries() )
    };
    webRTCpeer.share_sender_data({_to: recipient.color, receivedMetadata, timestamp})
    return webRTCpeer;
    // console.log(receivedMetadata);
    // createCard({giver: profile, receivedMetadata, timestamp});
}

function acceptTransaction({timestamp, card}) {

    const webRTCpeer = new WebRTCpeer({initiate: false});
    card.webRTCpeer = webRTCpeer;
    webRTCpeer.card = card;
    webRTCpeer.receive_sender_data(card['offer'])

}

// variables
const decoder = new TextDecoder('utf-8');

class WebRTCpeer {
    constructor({initiate}){
        // being receiver
        this.peer = new SimplePeer({
            trickle: false,
            initiator: initiate? true:false
        }),
        this.connected = false,
        this.id,
        this.chunkit,
        this.sendsignal,
        this.card,
        this.status,
        this.signaldata
    }
    // receive function setup
    async receive_sender_data(offer){
        
        this.peer.on('error', err => { this.errorHandler(err); })
        
        this.peer.signal(offer);
        
        this.peer.on('signal',async data => { this.onSignalHandler({data, _to: offer._from, _id: offer._id}); })

        this.peer.on('close', () => {this.peerDestroy();})
        
        await new Promise(resolve => {
            this.peer.on('connect', () => {
                // console.log("Connected by Receiver");
                this.connected = true;
                this.listenForData();
                resolve();
            })
        })
    }
    onSignalHandler({data, _to, _id, receivedMetadata}) {
        // console.log(data.type + " generated");
        data['_from'] = profile.color;
        data['_fromHex'] = profile.hex;
        data['_to'] = _to;
        data['_id'] = _id;
        
        if(receivedMetadata)
            data['receivedMetadata'] = receivedMetadata;

        this.signaldata = data;

        // answers.set(this.id, this);
        sendSignal(data);
    }

    errorHandler(err) {
        console.log(err);

        if (err.error.message == "Transport channel closed") {
            console.log("Transport channel closed")
            if (this.status == "receiving")
                errorCard({ card: this.card, error: err.error.message });
            else if(this.status == "sending")
                errorCard({ card: this.card, error: err.error.message, toTryAgain: true })
            this.peerDestroy();
        } else
            throw new Error();
    }

    // send function setup
    async share_sender_data({_to, receivedMetadata, timestamp}){
        
        // this.id = parseInt(`${new Date().getTime()}${totalSize}`);
        this.id = timestamp;
        
        this.peer.on('error', err => { this.errorHandler(err) })
        console.log(`send ${_to}`);

        // generate and post offer signal to backend
        this.peer.on('signal', data => {
            this.onSignalHandler({data, _to, _id: this.id, receivedMetadata})
        })

        this.peer.on('close', () => {this.peerDestroy();})

        //connect
        await new Promise(resolve => { this.peer.on('connect', () => {
            console.log('Connected by Sender', this);
            this.chunkit = new Chunkit({files:this.card.totalFilesChose.files, sender:this, card:this.card});
            this.chunkit.initialise();
            this.connected = true;
            fill_Card_With_LoadingStage({card: this.card, cardTag: this.card.cardTag, isSending: true});
            this.peer.on('data', data => {
                // decoder.decode(data) === "ready" ? this.chunkit.chunking() : null;
                decoder.decode(data) === "ready" ? this.chunkit.chunkThis()
                    : decoder.decode(data) === "next" ? this.chunkit.next()
                        : decoder.decode(data) === "allreceived" ? console.log("'allreceived' received")
                            : null;
            })
            resolve();
        }) })
    }

    acceptAnswerSignal(answer) {
        this.peer.signal(answer);
    }

    // method to decode received data and pass it to appropriate functions
    async listenForData(){
        const unchunkit = new Unchunkit(this.card);
        let receivedFiles = 0;
        let receivedSize = 0;
        this.peer.on('data', data => { 

            const decodedData = decoder.decode(data);

            let pkg = decodedData.toString();
            if(pkg.slice(0,4) === "meta"){
                this.status = "receiving";
                let meta = metaparse(pkg);
                initiate_Card_ProgressUpdaterInterval({card: this.card})
                unchunkit.initialiseFile({meta})
                    .then(() => this.peer.send("ready"));

            } else if(pkg==="done") {
                unchunkit.unchunk(data);
                this.peer.send("next");
                // this.status = "done";

            } else if(pkg==="allsent") {
                this.status = "done";
                this.peer.send("allreceived");
            } else if(pkg==="cancelConnection") {
                unchunkit.abortWriter();
                console.log("cancelConnection")

            } else {
                receivedSize += data.byteLength;
                unchunkit.unchunk(data);
            }
        })
    }
    async send_file_metadata(bytelength, extension, done){
        this.peer.send({filetype: "file", bytelength, extension, done})
    }

    async send_file_input(data){
        this.peer.send(data);
    }

    cancel_connection(){
        if(this.peer != null) this.peer.send("cancelConnection");
        this.peerDestroy();
    }
    
    peerDestroy(err){
        this.connected = false;
        // if (this.connected) socket.cancelSignal({
        //     _id: data['_id'],
        //     _to: data['_id'][0] !== '#'? data['_to'] : null,
        //     action: "send"});
        // if (this.connected) socket.cancelSignal({_id: data['_id'], action: "send"});
        try{this.peer.destroy()} catch(err){console.log(err)};
        this.peer = null;
    }

}

export { createTransaction, acceptTransaction, sentTransactions, receivedTransactions, WebRTCpeer }