

// import {
//     showDirectoryPicker,
//     showOpenFilePicker,
//     showSaveFilePicker,
//     FileSystemDirectoryHandle,
//     FileSystemFileHandle,
//     FileSystemHandle,
//     FileSystemWritableFileStream,
//     getOriginPrivateDirectory
// } from 'https://cdn.jsdelivr.net/gh/jimmywarting/native-file-system-adapter/src/es6.js'
import {
    showSaveFilePicker
} from 'https://cdn.jsdelivr.net/gh/jimmywarting/native-file-system-adapter/src/es6.js'
// } from 'native-file-system-adapter';
import { initiate_Card_ProgressUpdaterInterval, updateAfileProgress, afile_Done } from './cardUI.js';

// chunking function


export class Chunkit {

    constructor({files, sender, card}){
        this.files = files;
        this.sender = sender;
        this.card = card;
        // this.filesize = this.file.size,
        this.chunkSize = 16 * 1024;
        this.currentFile;
        this.receivedSize = 0;
        this.currentFileIndex;
        this.next;
        // this.currentChunk = 1
        // this.totalChunks = Math.ceil((this.filesize/this.chunkSize), this.chunkSize),
        // this.offset
    }

    async initialise() {

        this.sender.status = "sending";
        initiate_Card_ProgressUpdaterInterval({card: this.card});

        for(let [filename, file] of this.files.entries()) {

            console.log('Sending: ' + filename);
            this.sender.send_file_input(`metafile:${filename},blen:${file.size}`);
            this.currentFile = file;
            this.currentFileIndex = 0;
            await new Promise((resolve, reject) => {this.next = resolve});
            console.log("promise cleared");

            // await this.chunkThis(file);
        }

        this.sender.send_file_input("allsent");
        this.sender.status = "done";
    }

    async chunkThis() {
        let file = this.currentFile;
        let totalSize = file.size;
        let currentChunk = 1;
        let offset;
        const totalChunks = Math.ceil((totalSize/this.chunkSize), this.chunkSize);

        const afile = this.card.afileStorage.get(file.file.name);
        console.log(afile)
        this.chunking({
            file: await file.file.getFile(),
            totalSize, currentChunk, offset, totalChunks, afile
        })

        console.log(file)
        console.log(await file.file.getFile())
    }

    async chunking ({ file, totalSize, currentChunk, offset, totalChunks, afile }) {
        if(!this.sender.peer){
            // do Error
            return;
        };
        offset = (currentChunk - 1) * this.chunkSize;
        // console.log("offet:" + offset + "currentChunk:" + currentChunk);
        await this.bufferThisChunk(file, offset, totalSize, afile);
        if(currentChunk >= totalChunks){
            this.sender.send_file_input("done");
            afile_Done({afile, card: this.card})
            console.log("done");
        } else {
            await new Promise((resolve) => {
                setTimeout(() => resolve(), 10)
            })
            currentChunk++;
            await this.chunking({file, totalSize, currentChunk, offset, totalChunks, afile});
        };
    }


    async bufferThisChunk(file, offset, totalSize, afile) {
        await file.slice(offset, (offset + this.chunkSize)).arrayBuffer()
            .then(buffer => {
                this.sender.send_file_input(buffer);
                const fileDoneInSize = Math.min((offset + this.chunkSize), totalSize);
                console.log(buffer.byteLength);
                this.card.howMuchDoneInSize += buffer.byteLength;
                updateAfileProgress({ afile, fileDoneInSize, totalSize });
                // setTimeout(() => { }, 10);
            });
    }
}



// export const chunkit0 = (file, sender, box) => {
//     console.log('Sending: ' + file);
//     const filesize = file.size;
//     const chunkSize = 16 * 1024;
//     sender.send_file_input(`metafile:${file.name},blen:${filesize}`);
//     let offset;
//     let currentFilePart;

//     let currentChunk = 1;
//     const totalChunks = Math.ceil((filesize/chunkSize), chunkSize);
//     sender.status = "sending";

//     const chunking = async () => {
//         if(!sender.peer || !sender.connected)return;
//         offset = (currentChunk-1) * chunkSize;
//         await file.slice(offset, (offset+chunkSize)).arrayBuffer()
//             .then(buffer => {
//                 sender.send_file_input(buffer);
//                 box.updateProgress(Math.min((offset+chunkSize), filesize));
//                 console.log(currentChunk);
//                 setTimeout(() => {}, 10);
//             })
//         if(currentChunk >= totalChunks){
//             sender.send_file_input("done");
//             sender.status = "done";
//             box.end();
//             console.log("done");
//         } else {
//             await new Promise((resolve) => {
//                 setTimeout(() => resolve(), 10)
//             })
//             currentChunk++;
//             chunking();
//         };
//     }
//     chunking();
// }

export class Unchunkit {
    constructor(card){
        this.card = card,
        this.receivedMetadata = card.receivedMetadata,
        this.writer,
        this.receivedSize = 0;
    }
    async initialiseFile({meta}) {
        // console.log(this.receivedMetadata, meta.file);
        if( !this.receivedMetadata.files.has(meta.file) || this.receivedMetadata.files.get(meta.file)['size'] != meta.bytelength ) {
            console.log("fileMismatch");
            return "fileNotReportedEarlier";
        }
        this.currentAfile = this.card.afileStorage.get(meta.file);
        this.currentFileName = meta.file;
        console.log(meta)
        this.totalFileSize = meta.bytelength;

        await this.createRStream({meta});

    }
    async createRStream ({meta}) {
        // this.filestream = streamSaver.createWriteStream(this.meta.file, { size: this.meta.blen });
        await new Promise(resolve => {
            showSaveFilePicker({
                _preferPolyfill: false,
                suggestedName: meta.file,
                types: [],
                excludeAcceptAllOption: false // default
            }).then(handle => {
                return handle.createWritable()
                    .then(writr => {
                        this.writer = writr;
                        console.log("saveWriter:", this.writer)
                        resolve();
                    })
            }).catch(err => console.log("file save picker create error", err));
        })
        // this.writer = this.filestream.getWriter();
    }
    async unchunk (data) {
        if (data.toString() === "done") {
            
            this.writer.close();
            afile_Done({ filename: this.currentFileName, afile: this.currentAfile, card: this.card })
            // this.box.end();
            console.log("nedo");

        } else {
            console.log("writing");
            this.card.howMuchDoneInSize += data.byteLength;
            this.receivedSize += data.byteLength;
            updateAfileProgress({ afile: this.currentAfile, fileDoneInSize: this.receivedSize, totalSize: this.totalFileSize })
            this.writer.write(data)
        }
    }
    abortWriter () {
        this.writer.abort();
    }
}

export class Unchunkit0 {
    constructor(card){
        this.fileChunks = [],
        this.meta,
        this.filestream,
        this.writer,
        this.blob,
        this.card = card,
        this.cache = new Array(),
        this.processedbytelength = 0,
        this.cachedBytelength = 0
    }
    async createRStream () {
        // this.filestream = streamSaver.createWriteStream(this.meta.file, { size: this.meta.blen });
        await new Promise(resolve => {
            showSaveFilePicker({
                _preferPolyfill: true,
                suggestedName: this.meta.file,
                types: [],
                excludeAcceptAllOption: false // default
            }).then(handle => {
                return handle.createWritable()
                    .then(writr => {
                        this.writer = writr
                        console.log("saveWriter:", this.writer)
                        resolve();
                    })
            }).catch(err => console.log("file save picker create error", err));
        })
        // this.writer = this.filestream.getWriter();
    }
    unchunk (data) {
        if (data.toString() === "done") {
            
            this.writer.close();
            // this.box.end();
            console.log("nedo");

        } else this.writer.write(data);
    }
    setMeta (meta) {
        this.meta = meta;
    }
    async createRStream0 () {
        this.filestream = await streamSaver.createWriteStream(this.meta.file, { size: this.meta.blen });
        this.writer = await this.filestream.getWriter();
    }
    unchunk0 (data) {
        if (data.toString() === "done") {
            this.writer.close();
            this.card.end();
            console.log("nedo");
        } else this.writer.write(data);
    }
    createRStream1 () {
        // this.filestream = streamSaver.createWriteStream(this.meta.file, { size: this.meta.blen });
        const saveHandle = showSaveFilePicker({
            _preferPolyfill: true,
            suggestedName: this.meta.file,
            types: [],
            excludeAcceptAllOption: false // default
        }).then(handle => {
            handle.createWritable()
                .then(writr => {
                    this.writer = writr
                    console.log("saveWriter:", this.writer)
                })
        }).catch(err => console.log("file save picker create error"));
        // this.writer = this.filestream.getWriter();
    }
    unchunk1 (data) {
        if (data.toString() === "done") {
            
            console.log("this.writer: ", this.writer, " | this.meta.bytelength: ", this.meta.blen, " | ","processedbytelength",this.processedbytelength,"cachedByteLength",this.cachedBytelength)

            const writerclosetimer = setInterval(() => {
                // console.log("processedbytelength",this.processedbytelength,"cachedByteLength",this.cachedBytelength)
                if(this.writer && this.meta.bytelength <= this.processedbytelength + this.cachedBytelength) {

                    if(this.cache.length) this.doCache();

                    this.writer.close();
                    this.box.end();
                    console.log("nedo");
                    clearInterval(writerclosetimer);
                }
            }, 500)

        } else if (!this.writer) {
            console.log("cache add", data.byteLength);
            this.cache.push(data);
            this.cachedBytelength += data.byteLength;

        } else if (this.writer && this.cachedBytelength) {
            console.log("cache write");
            this.writer.write(data);
            this.processedbytelength += data.byteLength;

        } else this.writer.write(data);
    }
    doCache () {
        console.log("doCache fired");
        for(let cachedChunk in this.cache){
            // Buffer.from(cachedChunk)
            //     .then(buffer => this.writer.write(buffer))
            this.writer.write( cachedChunk );
            console.log("cachedChunk")
        }
        this.cache = new Array();
        this.processedbytelength += this.cachedBytelength;
        this.cachedBytelength = 0;
    }
}

export class Savefile {
    constructor(savechunk,meta){
        this.blob = savechunk,
        this.fileStream = streamSaver.createWriteStream(meta.file, {
          size: meta.blen // Makes the procentage visiable in the download
        }),
        // One quick alternetive way if you don't want the hole blob.js thing:
        this.readableStream = new Response(thisblob).body
        // with blob.js:
        //this.readableStream = this.blob.stream()
    }
    notSafariStream(){
        // more optimized pipe version
        // (Safari may have pipeTo but it's useless without the WritableStream)
        if (window.WritableStream && this.readableStream.pipeTo) {
            return this.readableStream.pipeTo(this.fileStream)
              .then(() => console.log('done writing'))
        }
    }
    forSafariStream(){
        // Write (pipe) manually
        window.writer = this.fileStream.getWriter()
    
        const reader = this.readableStream.getReader()
        const pump = () => reader.read()
          .then(res => res.done
            ? writer.close()
            : writer.write(res.value).then(pump))
    
        pump()
    
    }
        
}

// export { showSaveFilePicker }