
import { addChooseFileListeners } from './sendofferUI.js';
import { createSocket, webSocket } from './register.js';
import {  } from './receiveAsUI.js';

// let username = localStorage.getItem("username")? localStorage.getItem("username"):"void";
let offers = new Map();
let answers = new Map();

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('../sw.js')
        .then(reg => console.log('%c Service Worker Registered Successfully.', 'color:#44ff44'))
        .catch(err => console.log('%c Service Worker Registration Failed.', 'color:#ff4444', err));
}

addChooseFileListeners();
createSocket();

// setTimeout(function(){
//     webSocket.close();
// }, 15000);





console.log(`%c
██▒   █▓ ██▓ ▒█████   ██▓    ▓█████▄▄▄█████▓
▓██░   █▒▓██▒▒██▒  ██▒▓██▒    ▓█   ▀▓  ██▒ ▓▒
 ▓██  █▒░▒██▒▒██░  ██▒▒██░    ▒███  ▒ ▓██░ ▒░
  ▒██ █░░░██░▒██   ██░▒██░    ▒▓█  ▄░ ▓██▓ ░ 
   ▒▀█░  ░██░░ ████▓▒░░██████▒░▒████▒ ▒██▒ ░ 
   ░ ▐░  ░▓  ░ ▒░▒░▒░ ░ ▒░▓  ░░░ ▒░ ░ ▒ ░░   
   ░ ░░   ▒ ░  ░ ▒ ▒░ ░ ░ ▒  ░ ░ ░  ░   ░    
     ░░   ▒ ░░ ░ ░ ▒    ░ ░      ░    ░      
      ░   ░      ░ ░      ░  ░   ░  ░        
     ░                                       
`, 'color: #7000FF');

// ~~ T E S T   A R E A ~~